// Sponsor Bar
$custom-sponsors-grid: true;
$sponsor-image-max-height-lg: 96px;
$sponsor-image-max-width-lg: 201px;
$sponsor-image-max-height-sm: 76px;
$sponsor-image-max-width-sm: 128px;
$sponsor-images-per-line-mobile: 2;


.custom-sponsors{
  .column{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: $breakpoint-sm-max){
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width: #{(($sponsor-image-max-width-sm + 30px) * 3) + 40px}){
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: #{(($sponsor-image-max-width-sm + 30px) * 2) + 40px}){
      grid-template-columns: 1fr;
    }
    @media all and (-ms-high-contrast: none) and (max-width: $breakpoint-sm-max), (-ms-high-contrast: active) and (max-width: $breakpoint-sm-max) {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
    }
  }
  .pageEl{
    // @media screen and (max-width: (($breakpoint-sm-max - ($mobile-padding * 4)) / $sponsor-images-per-line-mobile)){
    //   flex: 1 1 (($breakpoint-sm-max - ($mobile-padding * 4)) / $sponsor-images-per-line-mobile); //180px;
    // }
    .heroPhotoElement{
      padding: 0 15px;
      img,
      a{
        width: auto;
        max-width: $sponsor-image-max-width-lg;
        max-height: $sponsor-image-max-height-lg;
        @media screen and (max-width: $breakpoint-sm-max){
          max-width: $sponsor-image-max-width-sm;
          max-height: $sponsor-image-max-height-sm;
        }
        display: block;
        margin: auto;
      }
      img[src*=".svg"]{
        width: 100%;
        height: 100%;
        height: $sponsor-image-max-height-lg;
        @media screen and (max-width: $breakpoint-sm-max){
          height: $sponsor-image-max-height-sm;
        }
      }
    }
  }
  .header-special{
    max-width: 100%;
    width: 100%;
    flex: 1 0 100%;
  }
  // Edit Mode
  // .edit_mode & {
  //   .heroPhotoElement img{
  //     max-width: 150px;
  //     max-height: 150px;
  //   }
  // }
}
