.mediaSlider{
  > h2:empty,
  > p:empty{
    display: none;
  }
}

$mse-paging-inside: true !default;
$mse-paging-height: 10px !default;
$mse-paging-item-width: 10px !default;
$mse-paging-item-height: 10px !default;
$mse-paging-item-space-between: 10px !default;
$mse-paging-item-background: #DEE1E3 !default;
$mse-paging-item-background-hover: $neutral-color !default;
$mse-paging-item-background-active: $neutral-color !default;

// Pagination Style
.sn-media-slider{
  .slider-pagination{
    height: $mse-paging-height;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    .paging-item{
      width: $mse-paging-item-width;
      height: $mse-paging-item-height;
      background-color: $mse-paging-item-background;
      margin-left: ($mse-paging-item-space-between/2);
      margin-right: ($mse-paging-item-space-between/2);
      &:hover{
        background-color: $mse-paging-item-background-hover;
      }
      &.flex-active{
        background-color: $mse-paging-item-background-active;
      }
    }
  }
}
// Paging Placement
.sn-media-slider .slider{
  // Bottom
  &.bottom-left-paging,
  &.bottom-center-paging,
  &.bottom-right-paging{
    margin-bottom: if($mse-paging-inside == true, 0px , $mse-paging-height * 2);
  }
  &.bottom-center-paging .slider-pagination,
  &.bottom-center-paging .slider-pagination-numbers{
    bottom: $mse-paging-height / 2;
    justify-content: center;
  }
  &.bottom-left-paging .slider-pagination,
  &.bottom-left-paging .slider-pagination-numbers{
    bottom: $mse-paging-height / 2;
    justify-content: flex-start;
  }
  &.bottom-right-paging .slider-pagination,
  &.bottom-right-paging .slider-pagination-numbers{
    bottom: $mse-paging-height / 2;
    justify-content: flex-end;
  }
  // Top
  &.top-left-paging,
  &.top-center-paging,
  &.top-right-paging{
    margin-top: if($mse-paging-inside == true, 0px , $mse-paging-height * 2);
  }
  &.top-center-paging .slider-pagination,
  &.top-center-paging .slider-pagination-numbers{
    top: $mse-paging-height / 2;
    justify-content: center;
  }
  &.top-left-paging .slider-pagination,
  &.top-left-paging .slider-pagination-numbers{
    top: $mse-paging-height / 2;
    justify-content: flex-start;
  }
  &.top-right-paging .slider-pagination,
  &.top-right-paging .slider-pagination-numbers{
    top: $mse-paging-height / 2;
    justify-content: flex-end;
  }
}

//

.sn-media-slider{
  .slider{
    .slide-text{
      display: flex;
      flex-flow: column;
      &.middle-left,
      &.middle-right,
      &.middle-center{
        justify-content: center;
      }
      &.top-left,
      &.top-right,
      &.top-center{
        justify-content: flex-start;
      }
      &.bottom-left,
      &.bottom-right,
      &.bottom-center{
        justify-content: flex-end;
      }

      &.middle-center,
      &.top-center,
      &.bottom-center{
        align-items: center;
        .slide-title,
        .slide-text{
          text-align: center;
          align-items: center;
        }
      }
      &.middle-left,
      &.top-left,
      &.bottom-left{
        align-items: flex-start;
        .slide-title,
        .slide-text{
          text-align: left;
          align-items: flex-start;
        }
      }
      &.middle-right,
      &.top-right,
      &.bottom-right{
        align-items: flex-end;
        .slide-title,
        .slide-text{
          text-align: right;
          align-items: flex-end;
        }
      }
    }
    .slide-title,
    .slide-description{
      width: 100%;
      font-family: $fontTwo;
    }
    .slide-title{
      // display: block; // override
      // color: #FFF; // override
      font-weight: 900; // override
      // font-size: 72px; // override
      // margin: 0; // override
      // text-shadow: none;  // override
      // line-height: 1; // override
    }
    .slide-description{
      // display: block; // override
      // color: #FFF; // override
      // font-size: 150%; // override
      // margin: 0; // override
      // text-shadow: none; // override
      // line-height: 1; // override
    }
  }
}
@mixin mse-image-overlay(){
  position: relative;
  &:before{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: .5;
    background-image: linear-gradient(to top, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    z-index: 1;
  }
}
@mixin mse-logo{
  position: relative;
  &:before{
    content: "";
    width: 260px;
    height: 38px;
    position: absolute;
    top: 18px;
    right: 22px;
    background-image: url(../images/white-TriaRink.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    @media screen and (max-width: $breakpoint-sm-max){
      width: 130px;
      height: 19px;
      top: 5px;
      right: 8px;
    }
  }
}

.media-slider-hero{
  .flexslider{
    @include mse-logo;
  }
  .sn-media-slider .media-wrapper{
    @include mse-image-overlay;
  }
  .sn-media-slider .slides .slide .media-wrapper img{
    opacity: .9;
  }
  .sn-media-slider .slides .slide .slide-overlay{
    max-width: $content-area-width;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    @include content-container-padding(2);
    padding-top: $mse-paging-height + 15px;
    @media screen and (min-width: $breakpoint-md-min){
      padding-bottom: 100px + $mse-paging-height + 15px;
    }
    @media screen and (max-width: $breakpoint-sm-max) {
      padding-bottom: 45px;
    }
  }

  // Adjust for Overlap
  .sn-media-slider .slider{
    &.bottom-center-paging .slider-pagination,
    &.bottom-center-paging .slider-pagination-numbers,
    &.bottom-left-paging .slider-pagination,
    &.bottom-left-paging .slider-pagination-numbers,
    &.bottom-right-paging .slider-pagination,
    &.bottom-right-paging .slider-pagination-numbers{
      .user_mode & {
        @media screen and (min-width: $breakpoint-md-min){
          bottom: (($mse-paging-height / 2) + 100px);
        }
      }
      @media screen and (max-width: $breakpoint-sm-max) {
        display: none;
      }
    }
  }

  // Text Styles
  .sn-media-slider{
    .slider{

      .slide-title,
      .slide-description{
        max-width: $content-area-width/2;
        color: blue;
      }

      .slide-title{
        display: block;
        margin: 0;
        font-weight: 900;
        font-size: 72px;
        @include responsive-font(9vw, 20px, 72px);
        // @media screen and (max-width: 600px){
        //   font-size: 32px;
        // }
        // @media screen and (max-width: 500px){
        //   font-size: 26px;
        // }
        line-height: 1;
        color: #FFF;
        text-shadow: none;
        font-family: $fontTwo;
      }
      .slide-description{
        margin: 0;
        text-shadow: none;
        line-height: 1;
        @include header-accent($neutral-color, 75px);
        @include header-accent-text(#FFF, 16px);
        order: -1;
      }
    }
  }
}



@mixin overlap($size){
  position: relative;
  top: -$size;
  margin-bottom: -$size;
}

.user_mode .overlap{
  .column{
    @media screen and (min-width: $breakpoint-md-min) {
      @include overlap(100px);
    }
    @media screen and (max-width: $breakpoint-sm-max) {
      @include overlap(45px);
    }
  }
}
.angled-top{
  .user_mode & {
    .column{
      z-index: 1;
    }
    position: relative;
    &:before {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      display: block;
      height: 20px;
      @media screen and (min-width: $breakpoint-md-min) {
        height: 60px;
      }
      // @media screen and (max-width: $breakpoint-sm-max) {
      //   height: 20px;
      // }
      background-image: url('data:image/svg+xml;utf8,<svg id="Layer_1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 60 60" preserveAspectRatio="none"><style>.st0{fill:#f8f9f9}</style><path class="st0" d="M60 60H0L60 0z"/></svg>');
      background-size: 100% 100%;
    }
  }
}
