.user_mode{
  // .theme-nav-item.home,
  // .theme-mobile-nav .theme-nav-link[data-title*="home"] + .theme-nav-forward,
  &.home .theme-sub-nav{
    display: none !important;
  }
  // .theme-main-nav .theme-nav .theme-nav-item.home + .theme-nav-item:before{
  //   display: none;
  // }

  &.home{
    #topNavPlaceholder{
      .has-main-nav.has-sub-nav &{
        height: $nav-title-bar-height + $main-nav-height;
        background-color: $main-nav-band-background-color;
      }
    }
    #topNavPlaceholder{
      .has-main-nav.has-sub-nav.nav-fixed &{
        height: $nav-title-bar-height-fixed + $main-nav-height-fixed;
      }
    }
  }
}
