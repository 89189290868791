// 4. Site Header
$site-banner-full-width: true !default;
$site-banner-full-width-container: true !default;
$site-banner-font-family: $fontTwo;
$site-banner-font-size: 32px;
$site-banner-line-height: 1;
$site-banner-font-weight: 900;
$site-banner-text-case: uppercase;
$site-banner-text-align: left;
$site-banner-text-color: #FFF;
$site-banner-content-padding: 30px;



// .home #displayBodyHeader { display: none; }
.site-banner-wrapper {
  display: flex;
  align-items: center;
  background-size: cover;
  position: relative;
  @if $site-banner-full-width != true {
    @include content-area-width;
  }

  .site-banner-text-wrapper {
    @if $site-banner-full-width-container == true {
      @include content-area-width;
    }
    padding-top: $site-banner-content-padding;
    padding-bottom: $site-banner-content-padding;
    // @include content-container-padding(1);
    @include content-container-padding(1);
    padding-left: 0 !important;
    width: 100%;
    line-height: $site-banner-line-height;
    text-align: $site-banner-text-align;
    @include font-smoothing;

    h2, h3 {
      font-size: 1em;
      line-height: 1.25;
      text-transform: none;
    }

    h1 {
      color: $site-banner-text-color !important;
      font-family: $site-banner-font-family;
      font-size: $site-banner-font-size;
      @media screen and (max-width: $breakpoint-sm-max){
        font-size: $site-banner-font-size - 4px;
      }
      font-weight: $site-banner-font-weight;
      line-height: $site-banner-line-height;
      text-transform: $site-banner-text-case;
      @include header-accent($neutral-color);
    }
  }
}

// @media (max-width: 699px) {
//   .site-banner-wrapper .site-banner-text-wrapper {
//     font-size: 4.5vw;
//
//     h1 { font-weight: 700; }
//     h2, h3 { display: none; }
//   }
// }
//
// @media (min-width: 700px) and (max-width: 1000px) {
//   .site-banner-wrapper .site-banner-text-wrapper { font-size: 3.6vw; }
// }
