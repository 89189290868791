/* Variables */
// 1. Imports
// 2. Functions
// 3. Defaults
// 4. Colors
// 5. Mixins
// 6. Animations

//ADDED
$asset-path-for: "https://app-assets1.sportngin.com";

// 1. Colors
$gray-lt-x: #F8F9F9;
$gray-lt: #EDF0F1;
$gray-md: #86898B;
$gray-dk: #2E3234;
$primary-color: #00502F;
$secondary-color: #C4122F;
$neutral-color: #EEE1CF;
$primary-color-dk: #00331E;

$accent-color: $secondary-color;
$highlight-color: $neutral-color;
$button-highlight_text: $neutral-color;
$background-color: #000;
$link-color: $primary-color-dk; // #0080ff;
$hover-color: $neutral-color;


// layout
$page-element-margin: 15px;


$link-color-opacity: rgba($link-color,0.7);

// Link colors
$link-color-darken-5: darken($link-color, 5%);
$link-color-darken-10: darken($link-color, 10%);
$link-color-darken-15: darken($link-color, 15%);
$link-color-darken-20: darken($link-color, 20%);
$link-color-darken-25: darken($link-color, 25%);
$link-color-darken-30: darken($link-color, 30%);
$link-color-darken-35: darken($link-color, 35%);
$link-color-darken-40: darken($link-color, 40%);
$link-color-darken-45: darken($link-color, 45%);
$link-color-darken-50: darken($link-color, 50%);

$link-color-lighten-5: lighten($link-color, 5%);
$link-color-lighten-10: lighten($link-color, 10%);
$link-color-lighten-15: lighten($link-color, 15%);
$link-color-lighten-20: lighten($link-color, 20%);
$link-color-lighten-25: lighten($link-color, 25%);
$link-color-lighten-30: lighten($link-color, 30%);
$link-color-lighten-35: lighten($link-color, 35%);
$link-color-lighten-40: lighten($link-color, 40%);
$link-color-lighten-45: lighten($link-color, 45%);
$link-color-lighten-50: lighten($link-color, 50%);

// Background colors
$background-color-darken-5: darken($background-color, 5%);
$background-color-darken-10: darken($background-color, 10%);
$background-color-darken-15: darken($background-color, 15%);
$background-color-darken-20: darken($background-color, 20%);
$background-color-darken-25: darken($background-color, 25%);
$background-color-darken-30: darken($background-color, 30%);
$background-color-darken-35: darken($background-color, 35%);
$background-color-darken-40: darken($background-color, 40%);
$background-color-darken-45: darken($background-color, 45%);
$background-color-darken-50: darken($background-color, 50%);

$background-color-lighten-5: lighten($background-color, 5%);
$background-color-lighten-10: lighten($background-color, 10%);
$background-color-lighten-15: lighten($background-color, 15%);
$background-color-lighten-20: lighten($background-color, 20%);
$background-color-lighten-25: lighten($background-color, 25%);
$background-color-lighten-30: lighten($background-color, 30%);
$background-color-lighten-35: lighten($background-color, 35%);
$background-color-lighten-40: lighten($background-color, 40%);
$background-color-lighten-45: lighten($background-color, 45%);
$background-color-lighten-50: lighten($background-color, 50%);

// Accent colors
$accent-color-darken-5: darken($accent-color, 5%);
$accent-color-darken-10: darken($accent-color, 10%);
$accent-color-darken-15: darken($accent-color, 15%);
$accent-color-darken-20: darken($accent-color, 20%);
$accent-color-darken-25: darken($accent-color, 25%);
$accent-color-darken-30: darken($accent-color, 30%);
$accent-color-darken-35: darken($accent-color, 35%);
$accent-color-darken-40: darken($accent-color, 40%);
$accent-color-darken-45: darken($accent-color, 45%);
$accent-color-darken-50: darken($accent-color, 50%);

$accent-color-lighten-5: lighten($accent-color, 5%);
$accent-color-lighten-10: lighten($accent-color, 10%);
$accent-color-lighten-15: lighten($accent-color, 15%);
$accent-color-lighten-20: lighten($accent-color, 20%);
$accent-color-lighten-25: lighten($accent-color, 25%);
$accent-color-lighten-30: lighten($accent-color, 30%);
$accent-color-lighten-35: lighten($accent-color, 35%);
$accent-color-lighten-40: lighten($accent-color, 40%);
$accent-color-lighten-45: lighten($accent-color, 45%);
$accent-color-lighten-50: lighten($accent-color, 50%);

// Hover colors
$hover-color-darken-5: darken($hover-color, 5%);
$hover-color-darken-10: darken($hover-color, 10%);
$hover-color-darken-15: darken($hover-color, 15%);
$hover-color-darken-20: darken($hover-color, 20%);
$hover-color-darken-25: darken($hover-color, 25%);
$hover-color-darken-30: darken($hover-color, 30%);
$hover-color-darken-35: darken($hover-color, 35%);
$hover-color-darken-40: darken($hover-color, 40%);
$hover-color-darken-45: darken($hover-color, 45%);
$hover-color-darken-50: darken($hover-color, 50%);

$hover-color-lighten-5: lighten($hover-color, 5%);
$hover-color-lighten-10: lighten($hover-color, 10%);
$hover-color-lighten-15: lighten($hover-color, 15%);
$hover-color-lighten-20: lighten($hover-color, 20%);
$hover-color-lighten-25: lighten($hover-color, 25%);
$hover-color-lighten-30: lighten($hover-color, 30%);
$hover-color-lighten-35: lighten($hover-color, 35%);
$hover-color-lighten-40: lighten($hover-color, 40%);
$hover-color-lighten-45: lighten($hover-color, 45%);
$hover-color-lighten-50: lighten($hover-color, 50%);

// Highlight colors
$highlight-color-darken-5: darken($highlight-color, 5%);
$highlight-color-darken-10: darken($highlight-color, 10%);
$highlight-color-darken-15: darken($highlight-color, 15%);
$highlight-color-darken-20: darken($highlight-color, 20%);
$highlight-color-darken-25: darken($highlight-color, 25%);
$highlight-color-darken-30: darken($highlight-color, 30%);
$highlight-color-darken-35: darken($highlight-color, 35%);
$highlight-color-darken-40: darken($highlight-color, 40%);
$highlight-color-darken-45: darken($highlight-color, 45%);
$highlight-color-darken-50: darken($highlight-color, 50%);

$highlight-color-lighten-5: lighten($highlight-color, 5%);
$highlight-color-lighten-10: lighten($highlight-color, 10%);
$highlight-color-lighten-15: lighten($highlight-color, 15%);
$highlight-color-lighten-20: lighten($highlight-color, 20%);
$highlight-color-lighten-25: lighten($highlight-color, 25%);
$highlight-color-lighten-30: lighten($highlight-color, 30%);
$highlight-color-lighten-35: lighten($highlight-color, 35%);
$highlight-color-lighten-40: lighten($highlight-color, 40%);
$highlight-color-lighten-45: lighten($highlight-color, 45%);
$highlight-color-lighten-50: lighten($highlight-color, 50%);

// Button highlight text colors
$button-highlight-text-darken-5: darken($button-highlight_text, 5%);
$button-highlight-text-darken-10: darken($button-highlight_text, 10%);
$button-highlight-text-darken-15: darken($button-highlight_text, 15%);
$button-highlight-text-darken-20: darken($button-highlight_text, 20%);
$button-highlight-text-darken-25: darken($button-highlight_text, 25%);
$button-highlight-text-darken-30: darken($button-highlight_text, 30%);
$button-highlight-text-darken-35: darken($button-highlight_text, 35%);
$button-highlight-text-darken-40: darken($button-highlight_text, 40%);
$button-highlight-text-darken-45: darken($button-highlight_text, 45%);
$button-highlight-text-darken-50: darken($button-highlight_text, 50%);

$button-highlight-text-lighten-5: lighten($button-highlight_text, 5%);
$button-highlight-text-lighten-10: lighten($button-highlight_text, 10%);
$button-highlight-text-lighten-15: lighten($button-highlight_text, 15%);
$button-highlight-text-lighten-20: lighten($button-highlight_text, 20%);
$button-highlight-text-lighten-25: lighten($button-highlight_text, 25%);
$button-highlight-text-lighten-30: lighten($button-highlight_text, 30%);
$button-highlight-text-lighten-35: lighten($button-highlight_text, 35%);
$button-highlight-text-lighten-40: lighten($button-highlight_text, 40%);
$button-highlight-text-lighten-45: lighten($button-highlight_text, 45%);
$button-highlight-text-lighten-50: lighten($button-highlight_text, 50%);


// 1. Imports
//
@mixin object-fit-poly($fit, $pos){
  object-fit: $fit;
  object-position: $pos;
  font-family: 'object-fit: #{$fit}; object-position: #{$pos};';
}

// 2. Functions
@function em($target) {
  @return $target * 1px;
}
@function rem($target) {
  @return $target * 1px;
}

// 3. Defaults
$theme: custom;

$fontOne: "Open Sans", sans-serif;
$fontTwo: "Lato", sans-serif;
$page-heading-font: bold 38px/1.25 $fontOne;
$base-font-size: 16;
$base-line-height: 1.5;
$base-font-weight: 400;

$transition-standard: .2s ease-in;
$transition-linear: .2s linear;

$base-padding: rem(25);
$base-padding-sm: rem(10);
$bootstrap-padding-sm: 10;
$bootstrap-padding-md: 15;

$snAdminBarHeight: 30px;
$site-footer-height: 46px;

$maxWidth:1200px;
$desktop_navHeight:140px;
$desktop_navHeight_slim:120px;
$tablet_navHeight:80px;
$mobile_navHeight:60px;
$top-nav-height:55px;
$top-nav-height-slim:45px;

$bodyCopyColorOne:#333;
$bodyCopyColorTwo: $gray-dk;
$bodyCopyColorThree:#999;

$borderColor:#ddd;
$borderWidth:2px;


// 4. Colors
$nav_second_level: $background-color-darken-5;
$nav_third_level: $background-color-darken-10;
$nav_fourth_level: $background-color-darken-15;

$table-body-background: $gray-lt-x;
$table-body-background-2: $gray-lt;
$table-cell-spacing: 2px;
$table-header-padding-vertical: 3px;
$table-header-padding-horizontal: 12px;
$table-cell-padding-vertical: 2px;
$table-cell-padding-horizontal: 12px;
$table-sort-icon-size: 14px;
$table-sort-icon-padding: $table-sort-icon-size + 4px;
$table-row-min-height: 40px;


// 5. Mixins

// Page Element Breakpoints
// Mixin for any page element that lives within the bootstrap columns.
// To use pass the pixel value of the breakpoint(s) below 768 that you want to target.
// This will generate the neccessary media queries for all possible column scenarios,
// and will ignore any that don't need to be used.
@mixin page-element-break($break){
  // Map defining column sizes within the "md" bootstrap range
  $columns: (
    9: (($break/.75) + ($bootstrap-padding-md * 2)),
    8: (($break/.66666666666) + ($bootstrap-padding-md * 2)),
    6: (($break/.50) + ($bootstrap-padding-md * 2)),
    4: (($break/.3333333333) + ($bootstrap-padding-md * 2)),
    3: (($break/.25) + ($bootstrap-padding-md * 2))
  );
  // Initial breakpoint (typically within the "sm" bootstrap range)
  // Note: Inital breakpoint also includes the panel padding, so any value you pass into $break will have the panel padding added to it.
  @media only screen and (max-width: (($break + ($bootstrap-padding-sm * 2)) * 1px)){
    @content;
  }
  // Cycle through columns map checking if values are within the "md" range and exporting media queries for those that match
  @each $column, $value in $columns {
    @if $value > 768 and $value < 1025 {
      @media only screen and (min-width: 768px) and (max-width: ($value * 1px)){
        .col-md-#{$column}{
          @content;
        }
      }
    }
    @if $value > 1025 { // Need to find a way to lopp these into one chained selector, not multiple media queries.
      @media only screen and (min-width: 768px){
        .col-md-#{$column}{
          @content;
        }
      }
    }
  }
}

@mixin displayCenter($maxWidth) {
  position: relative;
  display: block;
  margin: auto;
  max-width: $maxWidth;
  width: 100%;
}
@mixin aspect-ratio($ratio-or-width, $height: null) {
  $padding: if($height, percentage($height/$ratio-or-width), percentage(1/$ratio-or-width));
  &:before { content:''; float: left; padding-bottom: $padding;  }
  &:after  { content:''; display: table; clear: both;  }
}
@mixin move-to-bottom{
  top: initial;
  bottom: 0;
  transform: translateY(-10%);
}
@mixin vertical-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
@mixin truncate-text{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
}
@function dynamic-text-color($color) {
  @if (lightness($color) > 50) {
    @return #000000; // Lighter background, return dark color
  } @else {
    @return #ffffff; // Darker background, return light color
  }
}
@mixin line-pattern($line-color: $gray-lt-x, $gap-color:transparent) {
  background-color: $line-color;
}
@mixin line-pattern-border($height:1, $pos:top, $line-color: $gray-lt-x, $gap-color:transparent){
  border-style: solid;
  border-width: 0; // start by knocking out all the borders
  border-#{$pos}-color: $line-color;
  border-#{$pos}-width: ($height * 1px) !important;
}
@mixin special-header {
  // display: inline-block;
  // width: auto;
  // max-width: calc(100% - 9px); // Ensures that the box shadow shows at small sizes
  // height: auto;
  // padding: 10px 20px 10px 10px;
  // color: #fff;
  // background: $link-color;
  // font-size: 24px;
  // box-shadow:
  //   2px 0 #fff,
  //   7px 0 $accent-color;
}
@mixin content-tab-item() {
  transition: border $transition-standard;
  border-bottom: 5px solid transparent;
  display: inline-block;
  float: left;
}
@mixin content-tab() {
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 600;
  font-family: $fontTwo;
  padding: 7px 15px;
  text-align: center;
  text-decoration: none;
  transform: translate(0, 0);
  width: 100%;
}
@mixin table {
  // Add transparent spacing between cells using borders
  border-spacing: 0; // Override setting from elsewhere
  margin-top: $table-cell-spacing;
  // Apply transparent border to right and bottom of every cell and heading
  td, th {
    background-clip: padding-box !important; // !important is necessary but I don't know why
    border-color: transparent;
    border-style: solid;
    border-width: 0 $table-cell-spacing $table-cell-spacing 0;
    // Don't apply right border to last cell or heading in a row
    &:last-child {
      border-right: 0;
    }
  }
  // Don't apply bottom border to cells in last row (assumes THs are never in last row)
  tr:last-child {
    td {
      border-bottom: 0;
    }
  }

  border-collapse: initial; // Somewhere this is set to default to 'collapse'. Should remove default?
  width: 100%;

  tbody td { background: $table-body-background; }
  tbody .odd td { background: $table-body-background-2; }
}
@mixin table-td {
  font-family: $fontTwo;
  font-size: 14px;
  padding: $table-cell-padding-vertical $table-cell-padding-horizontal;
  text-align: center;
  height: $table-row-min-height; // Acts as min-height on table cells
  color: $gray-dk;

  // &.highlight { background: #fff; }
}
@mixin table-th {
  height: $table-row-min-height; // Acts as min-height on table cells
  padding: $table-header-padding-vertical $table-header-padding-horizontal;
  background: $primary-color; // Replace all instances of this with theme color var
  white-space: nowrap;
}
@mixin table-th-text {
  color: #fff;
  text-transform: none;
  line-height: 1.1;
  font-family: $fontTwo;
  font-weight: 900;
  font-size: 14px;
  }
@mixin table-team-logo {
  position: relative;
  display: inline-block;
  float: none;
  background-size: cover !important;
  width: 30px !important;
  vertical-align: middle;
  background: #fff;
  background-size: 100% auto;
  background-position: 50% 50%;
  margin: 2px 6px;
  border: none;
}
@mixin link-style($color: $neutral-color){
  font-family: $fontTwo;
  background-color: $primary-color;
  padding: 8px 15px;
  text-transform: uppercase;
  color: $color;
  font-size: 12px;
  line-height: 1.125;
  font-weight: 600;
  display: inline-block;
  transition: background-color $transition-linear;
  &:hover{
    background-color: $primary-color-dk;
  }
}
@mixin button-style{
  @include link-style;
}
@mixin text-field-style{
  height:28px;
  padding: 5px;
  margin-top: 5px;
  font-family:$fontTwo;
  font-size:.75em;
  background:#fff;
  border: 1px solid $borderColor;
  border-radius: 0;
  -webkit-appearance: none;
  transition: all .1s ease;
  &:hover{
    box-shadow:inset 0 1px 3px $borderColor;
    border: 1px solid $bodyCopyColorTwo;
  }
  &:focus{
    box-shadow:inset 0 1px 3px $borderColor;
    border: 1px solid $link-color;
    outline:0;
  }
}
@mixin dropdown-style{
  height:28px;
  font-family:$fontTwo;
  font-size:.75em;
  background:#fff;
  border: 1px solid $borderColor;
  transition: all .1s ease;
  white-space: normal;
  &:hover{
    box-shadow:0 1px 3px $borderColor;
    border: 1px solid $bodyCopyColorTwo;
  }
  &:focus{
    box-shadow:0 1px 3px $borderColor;
    border: 1px solid $link-color;
    outline:0;
  }
}
@mixin caption-style {
  color: $bodyCopyColorTwo;
  font-size: rem(12);
  font-style: italic;
}
@mixin list-item {
  padding: 20px 0; // $base-padding 0;
  @include line-pattern-border();
  &:nth-of-type(1){border:none;}
}
@mixin link-icon-left {
  position:absolute;
  font-family: FontAwesome;
  font-size:rem(14);
  top:0;
  left:5px;
}
@mixin sport-table-label {
  @include special-header;
  position: relative;
  $icon-size: 20px;
  $icon-spacing: 10px;

  .teamLogo-tiny {
    left: 14px;
    position: absolute;
    top: 14px;
  }

  * { color: inherit; }

  h4.teamName {
    font-size: 24px;
    margin: 0;
    padding-left: $icon-size + $icon-spacing;
  }

  .seasonDisplay,
  .expandedView {
    display: block;
    font-size: 12px;
    font-weight: normal;
    text-transform: none;
  }

  .linkText { display: block; }
  .season-separator { display: none; }
}
@mixin sport-table-label-short {
  max-width: 100%;
  padding-left: 10px;

  .teamLogo-tiny { display: none; }

  h4 > * {
    display: block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
@mixin link-with-arrow() {
  color: $accent_color;
  font-size: 13px;
  text-transform: uppercase;

  &:after {
    color: $link_color;
    content: '\f061'; // fa-arrow-right
    display: inline-block;
    font-family: 'FontAwesome';
    margin: -.1em 0 0 .4em;
    font-size: .9em;
  }
}
%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
@mixin word-wrap() {
  word-break: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}
@mixin font-smoothing(){
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// 6. Animations
@-webkit-keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}
@keyframes dropdown-season {
  0% {
    opacity: 0;
    top: 90%;
  }
  100% {
    opacity: 1;
    top: 100%;
  }
}

//ADMIN
// MIXINS

@mixin no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


// ICONS
@mixin fa-icon {
  font: normal normal normal 14px/1 FontAwesome;
}
@mixin fa-icon-lg {
  @include fa-icon;
  font-size: 1.33333333em;
}
@mixin fa-icon-2x {
  @include fa-icon;
  font-size: 2em;
}
@mixin fa-icon-3x {
  @include fa-icon;
  font-size: 3em;
}
@mixin fa-icon-4x {
  @include fa-icon;
  font-size: 4em;
}
@mixin fa-icon-5x {
  @include fa-icon;
  font-size: 5em;
}


// FONTS - just guessing until we have Whitney
$sn-font-weight-light: 100;
$sn-font-weight-normal: 300;
$sn-font-weight-medium: 500;
$sn-font-weight-heavy: 700;

$sn-font-size-xs: 10px;
$sn-font-size-sm: 12px;
$sn-font-size-md: 14px;
$sn-font-size-lg: 16px;
$sn-font-size-xl: 18px;

@mixin sn-default-font {
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif; // temp until we get Whitney installed
}

// SHADOWS


// GRADIENTS

@mixin sn-clickable {
  cursor: pointer;
  background: linear-gradient(white, #f1f3f4);
  border: 1px solid #b9c1c6;
}

@mixin sn-clickable-hover {
  background: linear-gradient(#f1f3f4, white);
  border-color: #808f98;
}

@mixin sn-clickable-active {
  @include sn-clickable-bg-hover;
  box-shadow: 0 0 8px rgba(0,0,0,0.5);
}

@mixin sn-clickable-disabled {
  cursor: default;
  color: #d5dadd;
  background: #ffffff;
  border-color: #d5dadd;
}

// ——————————

// SEARCH BOX
$site-search-height: 26px;
$site-search-spacing: 8px;
$site-search-border-width: 1px;
$site-search-input-font-size: 14px;
$site-search-input-color: #666;
$site-search-input-border-color: rgba(255,255,255,0.1);
$site-search-input-background: rgba(255,255,255,0.8);
$site-search-input-background-focus: rgba(255,255,255,1);
$site-search-submit-color: #fff;
$site-search-submit-border-color: rgba(255,255,255,0.1);
$site-search-submit-background: $link-color-darken-10;
$site-search-submit-background-focus: $link-color-darken-20;
$site-search-submit-font-size: 12px;
//
// STIE TAGLINE
$site-tagline-text-color :#fff;
$site-tagline-color: $link-color;
$site-tagline-spacing: 15px;
$site-tagline-separator-size: 1px;
$site-tagline-separator: $site-tagline-separator-size solid rgba(255,255,255,0.1);
$site-tagline-height: 40px;
$site-tagline-height-fixed: .01px; // hide it (0px does not animate)


// ——————————
// Config
// Global Layout
// Page Manager
// Page Title

// Variables
$enable-full-width-layout: true;
$full-width-page-id: null;

$desktop-padding: 15px;
$mobile-padding: 10px;

$content-area-width: $maxWidth;
$content-area-padding: ($desktop-padding * 2);
$content-area-padding-mobile: ($mobile-padding * 2);
$content-area-background: #FFF;
$site-background: $gray-lt-x;

$breakpoint-sm-max: 767px;
$breakpoint-md-min: 768px;
$breakpoint-intermediate-min: 768px;
$breakpoint-intermediate-max: 999px;



// Global Layout

@mixin page-element-padding(){
  padding-top: $desktop-padding;
  padding-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-top: $mobile-padding;
    padding-bottom: $mobile-padding;
  }
}
@mixin page-element-margin(){
  margin-top: $desktop-padding;
  margin-bottom: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    margin-top: $mobile-padding;
    margin-bottom: $mobile-padding;
  }
}
@mixin column-padding(){
  padding-left: $desktop-padding;
  padding-right: $desktop-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-left: $mobile-padding;
    padding-right: $mobile-padding;
  }
}
@mixin content-container-padding($multiplier: 1){
  padding-left: $desktop-padding * $multiplier;
  padding-right: $desktop-padding * $multiplier;
  @media screen and (max-width: $breakpoint-sm-max){
    padding-left: $mobile-padding * $multiplier;
    padding-right: $mobile-padding * $multiplier;
  }
}
@mixin content-area-width{
  width: 100%;
  max-width: $content-area-width;
  margin-left: auto;
  margin-right: auto;
}


// Page Manager
$page-manager-width: 60px;


// Page Title
@mixin site-page-title{
  font-size: 2.25em;
  font-weight: 600;
  border-bottom: $borderWidth solid $borderColor;
  text-transform: initial;
}


// Account Navigation Options
$account-nav-bottom: true;
$account-nav-mobile-bottom: true;
$account-nav-background: #23282b;
$account-nav-color: #FFF;
$account-nav-secondary-color: $account-nav-color;
$account-nav-secondary-color-hover: darken($account-nav-color, 20%);
$account-nav-font-size: 14px;
$account-nav-height-desktop: 40px;
$account-nav-height-mobile: 44px;
$account-nav-z-index: 399;
$account-nav-icon-width: 32px;

$nav-title-bar-height: 35px;
$nav-title-bar-height-fixed: .01px;
$nav-title-bar-band-background-color: #EEE1CF;
$nav-title-bar-text-color: $gray-dk;
$nav-title-bar-font-size: 14px;
$nav-title-bar-text-case: uppercase;
$nav-title-bar-text-weight: 700;

// Main Navigation Options
$main-nav-width-max: $content-area-width;
$main-nav-height: 75px;
$main-nav-height-fixed: 75px;
$main-nav-band-background-color: #FFF;
$main-nav-flex-alignment: flex-start;
$main-nav-item-padding: 35px;

$main-nav-cascade-styles: true;
$main-nav-font-size: 14px;
$main-nav-text-case: none;
$main-nav-text-align: center;
$main-nav-text-weight: 700;
$main-nav-text-color: #01100A;
$main-nav-text-color-hover: $primary-color-dk;
$main-nav-text-color-selected: $primary-color-dk;
$main-nav-text-color-disabled: slategrey;
$main-nav-item-background-color: transparent;
$main-nav-item-background-color-hover: transparent;
$main-nav-item-background-color-selected: $main-nav-item-background-color-hover;
$main-nav-item-background-color-disabled: transparent;


// Dropdown Navigation Options
$dropdown-nav-min-width: 200px;
$dropdown-nav-min-height: 35px;
$dropdown-nav-transition-distance: 20px;

$dropdown-nav-cascade-styles: true;
$dropdown-nav-container-background-color: $gray-lt-x;
$dropdown-nav-container-border-size: 0px;
$dropdown-nav-container-border: $dropdown-nav-container-border-size solid transparent;
$dropdown-nav-text-align: left;
$dropdown-nav-text-case: none;
$dropdown-nav-text-weight: 700;
$dropdown-nav-font-size: 14px;
$dropdown-nav-line-height: 1.2;
$dropdown-nav-text-color: $main-nav-text-color;
$dropdown-nav-text-color-hover: $main-nav-text-color-hover;
$dropdown-nav-text-color-selected: $dropdown-nav-text-color-hover;
$dropdown-nav-text-color-disabled: $main-nav-text-color-disabled;
$dropdown-nav-item-padding: 20px;
$dropdown-nav-item-background-color: transparent;
$dropdown-nav-item-background-color-hover: $main-nav-item-background-color-hover;
$dropdown-nav-item-background-color-selected: $dropdown-nav-item-background-color-hover;
$dropdown-nav-item-background-color-disabled: $main-nav-item-background-color-disabled;

$dropdown-nav-2-container-background-color: $gray-lt;
$dropdown-nav-2-container-border-size: null;
$dropdown-nav-2-container-border: null;
$dropdown-nav-2-text-align: null;
$dropdown-nav-2-text-case: null;
$dropdown-nav-2-text-weight: null;
$dropdown-nav-2-font-size: null;
$dropdown-nav-2-line-height: null;
$dropdown-nav-2-text-color: null;
$dropdown-nav-2-text-color-hover: null;
$dropdown-nav-2-text-color-selected: null;
$dropdown-nav-2-text-color-disabled: null;
$dropdown-nav-2-item-background-color: null;
$dropdown-nav-2-item-background-color-hover: null;
$dropdown-nav-2-item-background-color-selected: null;
$dropdown-nav-2-item-background-color-disabled: null;


// Sub Navigation Options
$sub-nav-font-size: 14px;
$sub-nav-text-color: #FFF;
$sub-nav-text-color-hover: $neutral-color;
$sub-nav-text-color-disabled: slategrey;
$sub-nav-item-background-color-disabled: rgba(lightgray, .5);
$sub-nav-band-background-color: $primary-color;
$sub-nav-height: 45px;
$sub-nav-height-fixed: 35px;
$sub-nav-item-background-color: transparent;
$sub-nav-item-background-color-hover: transparent;
$sub-nav-item-text-case: none;
$sub-nav-item-text-weight: normal;
$sub-nav-item-padding: 10px;
$sub-nav-flex-alignment: flex-start;

// Sub Navigation Title Options
$sub-nav-title-font-size: $sub-nav-font-size;
$sub-nav-title-height: $sub-nav-height;
$sub-nav-title-text-case: capitalize;
$sub-nav-title-text-weight: bold;
$sub-nav-title-background-color: transparent;
$sub-nav-title-text-color: #FFF;
$sub-nav-title-padding: 10px;

// Sub Navigation Dropdown Options
$sub-nav-dropdown-font-size: 16px;
$sub-nav-dropdown-text-case: none;
$sub-nav-dropdown-line-height: 1.2;
$sub-nav-dropdown-min-width: 200px;
$sub-nav-dropdown-min-height: 35px;
$sub-nav-dropdown-transition-distance: 20px;
$sub-nav-dropdown-container-background-color: $sub-nav-band-background-color;
$sub-nav-dropdown-container-border: 0;
$sub-nav-dropdown-text-align: left;

// Desktop Navigation Sizes
$desktop-nav-height: $nav-title-bar-height + $main-nav-height + $sub-nav-height;
$desktop-nav-height-fixed: $nav-title-bar-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed;
$desktop-nav-height-no-subnav: $nav-title-bar-height + $main-nav-height;
$desktop-nav-height-no-subnav-fixed: $nav-title-bar-height-fixed + $main-nav-height-fixed;
$nav-placeholder-height: $desktop-nav-height; // Remove after replacing these in code base
$nav-placeholder-height-fixed: $desktop-nav-height-fixed; // Remove after replacing these in code base


// Site Logo Options
$site-logo-size: $desktop-nav-height;  // Remove after replacing these in code base
$site-logo-size-fixed: $desktop-nav-height-fixed;  // Remove after replacing these in code base
$site-logo-width-desktop: $site-logo-size;
$site-logo-height-desktop: $site-logo-size;
$site-logo-width-desktop-fixed: $site-logo-size-fixed;
$site-logo-height-desktop-fixed: $site-logo-size-fixed;
$site-logo-width-mobile: 10px;
$site-logo-height-mobile: 10px;
$site-logo-padding: 5px;
$site-logo-offset: $site-logo-width-desktop;
$site-logo-offset-fixed: $site-logo-width-desktop-fixed;


$mobile-nav-height: 60px;





// SLIDE NAV CONFIG
$slide-nav-width: 250px;
$slide-nav-tablet-width: 400px;
$slide-nav-font-size: 16px;
$slide-nav-background: rgba(#FFF,0.75);
$slide-nav-background-collapsed: rgba(#FFF,0.95);

$slide-nav-content-spacing: 10px;
$slide-nav-content-background: $link_color;

$slide-nav-toggle-background: transparent;
$slide-nav-toggle-background-collapsed: transparent;
$slide-nav-toggle-color: $primary-color;
$slide-nav-toggle-color-collapsed: $primary-color;

$slide-nav-title-color: $primary-color;
$slide-nav-title-background: $gray-lt-x;
$slide-nav-title-border: 1px solid $gray-lt;
$slide-nav-title-height: $mobile-nav-height;

$slide-nav-back-icon: "\f053";
$slide-nav-back-color: $primary-color;
$slide-nav-back-background: $gray-lt-x;
$slide-nav-back-background-hover: $gray-lt;
$slide-nav-back-border: 1px solid $gray-lt; // seems to double up when transparent

$slide-nav-forward-icon: "\f054";
$slide-nav-forward-color: $primary-color;
$slide-nav-forward-background: $gray-lt-x;
$slide-nav-forward-background-hover: $gray-lt;
$slide-nav-forward-border: 1px solid $gray-lt; // seems to double up when transparent

$slide-nav-menu-background: transparent;

$slide-nav-item-color: $primary-color;
$slide-nav-item-color-disabled: #999;
$slide-nav-item-background: $gray-lt-x;
$slide-nav-item-background-hover: $gray-lt;
$slide-nav-item-background-selected: $gray-lt;
$slide-nav-item-background-disabled: transparent;
$slide-nav-item-background-disabled-hover: $gray-lt-x;
$slide-nav-item-background-disabled-selected: $gray-lt;
$slide-nav-item-border: 1px solid $gray-lt;
$slide-nav-item-height: 44px;



// Site Footer Colophon
$footer-colophon-se-logo-black-color: true;
$footer-colophon-se-logo-white-color: false;
$footer-colophon-se-logo-white: false;

$footer-colophon-max-width: $content-area-width;
$footer-colophon-band-background-color: gainsboro;
$footer-colophon-content-background-color: transparent;
$footer-colophon-item-padding: 10px;
$footer-colophon-flex-justification: center;
$footer-colophon-flex-align: center;

$footer-colophon-font-size: 14px;
$footer-colophon-line-height: 1.75;
$footer-colophon-text-case: none;
$footer-colophon-text-color: $gray-dk;
$footer-colophon-link-color: $primary-color;
$footer-colophon-link-color-hover: $primary-color-dk;


$site-container-padding: 30px;

@mixin header-accent($color, $width: 50px, $height: 3px){
  display: flex;
  align-items: center;
  &:before{
    content: "";
    height: $height;
    width: 100%;
    max-width: $width;
    background-color: $color;
    display: inline-block;
    margin-right: 10px;
  }
}
@mixin header-accent-text($color: $gray-md, $size: 12px){
  color: $color;
  font-size: $size;
  font-weight: 700;
  text-transform: uppercase;
}


@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive) == 'vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}
