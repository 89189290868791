$bottom-options-font-size: 11px;
$bottom-options-text-wieght: 600;
$bottom-options-text-color: $gray-dk;
$bottom-options-text-color-hover: $primary-color;

// Page / Element Bottom Options
.pageOptions,
.extendedOptions {
  @include line-pattern-border($pos: top);
  font-size: $bottom-options-font-size;
  font-family: $fontTwo;
  font-weight: $bottom-options-text-wieght;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;

  li {
    list-style: none;
    float: left;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;

    &:not(:first-child):before {
      content: "|";
      font-size: 14px;
      font-weight: 400;
      line-height: 2;
      font-family: $fontTwo;
      margin-left: 12px;
    }
  }

  a {
    color: $bottom-options-text-color;
    // display: inline-block;
    margin: 0 1.5ch;
    padding-bottom: 0;
    white-space: nowrap;
    &:last-child{
      margin-right: 0;
    }

    > img {
      vertical-align: middle !important;
    } // overrid AddThis module

    &:hover {
      color: $bottom-options-text-color-hover;
      text-decoration: none;
    }
    &.icon:before{
      font-size: inherit;
    }
  }
}

//Sharing icons
.sn-share-icons {
  display: block;
  color: $bottom-options-text-color;
  font-size: 0.875rem;

  div {
    display: inline-block;
    padding: 0 2px;
    vertical-align: middle;
  }

  .sn-share-text {
    position: relative;
    padding-left: 17px;
    transform: translate(0, -2px);

    &:before {
      content: "\f1e0";
      position: absolute;
      font-family: FontAwesome;
      font-size: 0.875rem;
      top: 0;
      left: 0;
    }
  }
}

.sharingOptions {
  width: 100%;
  text-align: center;
  margin-top: 15px;
  padding: 15px 0 10px 0;
  border-top: $borderWidth solid $gray-lt;
}

.bottomOptions{
  .pageOptions{
    padding-top: 10px;
    border-top-color: $gray-lt;
    a{
    }
  }
}
