/* Event Aggregators */
// 1. Global Styles
// 2. List Views
// 3. List Views - Media Queries
// 4. Five Day View
// 5. Five Day View - Media Queries


// 1. Global Styles
.eventAggregatorElement {
  .aggHeader {
    @include special-header;
  }
}

// 2. List Views
.eventAggregatorElement {
  .vevent {
    font-family: $fontTwo;
    position: relative;
    padding: $base-padding 0;
    color: $gray-dk;
    + .vevent { padding-top: 0; }

    &.odd,
    &.even {
      background-color: transparent;
    }
  }
  .summary,
  .details {
    list-style: none;
    padding-left: 95px;
  }
  .summary {
    font-family: $fontTwo;
    text-transform: none;
  }
  .time{
    margin-top: 10px;
    font-size: 14px;
    // font-weight: 600;
    abbr{
      text-decoration: none;
    }
  }
  .time,
  .location {
    display: inline-block;
    margin-right: .5em;
    font-size: 14px;
  }
  .location {

    &, > a {
      color: $primary-color;
      font-size: 14px;
      font-weight: 700;
      text-transform: none;
    }
  }
  .tags {
    font-size: 12px;
    font-weight: 400;
    a{
      color: $primary-color;
      font-weight: 700;
    }
  }
  .description {
    margin-top: em(12);
  }
  .dateImage {
    display: block;
    list-style: none;
    background: $neutral-color;
    padding: .5em;
    position: relative;
    float: left;
    width: 80px;
    box-sizing: border-box;
    // &:after {
    //   content: '';
    //   position: absolute;
    //   top: 50%;
    //   right: 0;
    //   transform: translate(45%, -50%) rotate(45deg);
    //   background: inherit;
    //   width: .75em;
    //   height: .75em;
    // }
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    font-family: $fontTwo;
    .month {
      font-size: 12px;
      font-weight: 600;
      letter-spacing: .5px;
      color: $primary-color;
    }
    .date {
      color: $primary-color;
      font-size: 42px;
      font-weight: 900;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  .formElement {
    padding: 10px 0; // This should be better defined in global form styles (or removed from there to be styled per element)
    [id$="_start_date_day"],
    [id$="_end_date_day"] {
      width: 8em;
    }
  }
}

// 3. List Views - Media Queries
@include page-element-break(330) {
  .eventAggregatorElement {
    .dateImage {
      float: none;
      margin-bottom: 1em;
      // &:after {
      //   bottom: 0;
      //   left: 50%;
      //   top: initial;
      //   right: initial;
      //   transform: translate(-50%, 45%) rotate(45deg);
      // }
    }
    .summary,
    .details {
      padding-left: 0;
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-6,
    .col-md-3 {
      .eventAggregatorElement {
        .dateImage {
          position: absolute;
          margin-bottom: 0;
          // &:after {
          //   top: 50%;
          //   right: 0;
          //   bottom: initial;
          //   left: initial;
          //   transform: translate(45%, -50%) rotate(45deg);
          // }
        }
        .summary,
        .details {
          padding-left: 95px;
        }
      }
    }
  }
  .layout-25-50-25 {
    .col-md-6,
    .col-md-3 {
      .eventAggregatorElement {
        .dateImage {
          position: relative;
          margin-bottom: 1em;
          // &:after {
          //   bottom: 0;
          //   left: 50%;
          //   top: initial;
          //   right: initial;
          //   transform: translate(-50%, 45%) rotate(45deg);
          // }
        }
        .summary,
        .details {
          padding-left: 0;
        }
      }
    }
  }
}

// 4. Five Day View
.eventPreview {
  text-align: left;
  width: 100%;
  table-layout: fixed;
  th {
    @include table-th;
    @include table-th-text;
    word-break: break-word;
    a {
      color:#FFF;
      font-weight: 600;
    }
  }
  td {
    font-size: 12px;
    padding: 10px;
    background: #eee;
    vertical-align: top;
    word-break: break-word;
    &:before {
      content: attr(data-week-view-date)'';
      display: none;
      width: 100%;
      position: relative;
      box-sizing: border-box;
      padding: 7px 12px;
      border-bottom: $table-cell-spacing solid #FFF;
      background: $link_color;
      color: #fff;
      text-transform: uppercase;
      font-family: $fontOne;
      font-size: 1em;
      line-height: 1.1;
      font-weight: 600;
    }
  }
  td,
  th {
    border: $table-cell-spacing solid #FFF;
    &:first-child {
      border-left: 0;
    }
    &:last-child {
      border-right: 0;
    }
  }
  .event + .event {
    padding-top: 10px;
  }
  .noentry {
    word-break:normal;
  }
}

// 5. Five Day View - Media Queries
@include page-element-break(430) {
  .eventPreview {
    border-top: $table-cell-spacing solid #FFF;
    &,
    tbody,
    tr,
    td {
      display: block;
    }
    thead {
      display: none;
    }
    td {
      border: 0;
      padding: 0;
      margin-bottom: $table-cell-spacing;
      word-break: word-break;
      &:before {
        display: block;
      }
    }
    .event {
      padding: 10px;
      & + .event {
        padding-top: 0;
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-6 {
      .eventPreview {
        display: table;
        border-top: 0;
        thead {
          display: table-header-group;
        }
        tbody {
          display: table-row-group;
        }
        tr {
          display: table-row;
        }
        td,
        th {
          display: table-cell;
        }
        td {
          padding: 10px;
          margin-bottom: none;
          word-break: word-break;
          &:before {
            display: none;
          }
        }
        .event {
          padding: 0;
        }
      }
    }
  }
  .layout-25-50-25 {
    .col-md-6 {
      .eventPreview {
        border-top: $table-cell-spacing solid #FFF;
        &,
        tbody,
        tr,
        td {
          display: block;
        }
        thead {
          display: none;
        }
        td {
          border: 0;
          padding: 0;
          margin-bottom: $table-cell-spacing;
          word-break: word-break;
          &:before {
            display: block;
          }
        }
        .event {
          padding: 10px;
          & + .event {
            padding-top: 0;
          }
        }
      }
    }
  }
}
@media only screen and (min-width: 891px) and (max-width: 999px) {
  .layout-25-25-50,
  .layout-50-25-25 {
    .col-md-3 {
      .eventPreview {
        display: table;
        border-top: 0;
        thead {
          display: table-header-group;
        }
        tbody {
          display: table-row-group;
        }
        tr {
          display: table-row;
        }
        td,
        th {
          display: table-cell;
        }
        td {
          padding: 10px;
          margin-bottom: none;
          word-break: word-break;
          border: $table-cell-spacing solid #FFF;
          &:first-child {
            border-left: 0;
          }
          &:last-child {
            border-right: 0;
          }
          &:before {
            display: none;
          }
        }
        .event {
          padding: 0;
        }
      }
    }
  }
}



// Custom
.event-cards{
  .eventAggregatorElement{
    .aggHeader{ display: none; }
    .extendedOptions{
      width: 100%;
      grid-column: 1/ -1;
      padding: 0;
      text-align: center !important;
      justify-content: center;
      font-size: 0;

      // @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      //   -ms-grid-column: 1;
      //   -ms-grid-column-span: 5;
      //   -ms-grid-row: 3;
      // }

      a{ display: none; }
      [title="View All"]{
        &:before{ display: none; }
        display: block;
        @include link-style(#FFF);
        padding: 16px 15px;
        @media screen and (min-width: $breakpoint-md-min) {
          min-width: 200px;
        }
      }
    }

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @media screen and (max-width: $breakpoint-sm-max){ grid-template-columns: repeat(1, 1fr); }
    grid-template-rows: auto;
    grid-gap: 30px 30px;
    // @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    //   // display: -ms-grid;
    //   // -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
    //   // -ms-grid-rows: auto 30px 1fr 30px;
    //   display: flex;
    //   flex-flow: row wrap;
    //   width: calc(100% + 60px);
    //   margin-left: -30px;
    //   margin-right: -30px;
    // }

    .vevent{
      font-family: $fontTwo;
      color: $gray-dk;
      background-color: #FFF;
      padding: 25px 30px;
      // &:nth-of-type(3n+1){ @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { -ms-grid-column: 1; -ms-grid-row: 1; } }
      // &:nth-of-type(3n+2){ @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { -ms-grid-column: 3; -ms-grid-row: 1; } }
      // &:nth-of-type(3n+3){ @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { -ms-grid-column: 5; -ms-grid-row: 1; } }
    }

    .dateImage{
      // Resets
      background: transparent;
      padding: 0;
      float: none;
      width: auto;
      text-align: unset;
      margin-bottom: 10px;
      &:after{ display: none; }

      @include header-accent($neutral-color);
    }
    .month:after{
      content: ".";
      margin-right: .5ch;
    }
    .month,
    .date{
      display: inline-block;
      font-weight: bold;
      font-size: 16px;
      color: $primary-color;
      text-transform: uppercase;
      letter-spacing: normal;
    }
    .summary,
    .details {
      padding-left: 0;
    }
    .details{
      line-height: 1.2;
    }
    .summary a{
      color: $gray-dk;
      font-size: 22px;
      &:hover{
        color: $primary-color-dk;
      }
    }
    .time{
      margin-top: 12px;
      font-size: 14px;
      font-weight: 400;
      abbr{
        text-decoration: none;
      }
    }
    .location{
      color: $primary-color;
      font-size: 14px;
      font-weight: 700;
      text-transform: none;
    }
    .tags{
      font-size: 12px;
      font-weight: 400;
      margin-top: 10px;
      a{
        color: $primary-color;
        font-weight: 700;
      }
    }
    .description{
      font-size: 14px;
      margin-top: 10px;
    }
  }
  &.hide-description{
    .eventAggregatorElement .description{
      display: none;
    }
  }
}

.hide-event-header{
  .aggHeader{
    display: none;
    + .vevent {
      padding-top: 0;
    }
  }
}




@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .event-cards{
    margin-left: -15px;
    margin-right: -15px;
    .eventAggregatorElement{
      display: flex;
      flex-flow: row wrap;
      .vevent{
        margin: 15px;
        width: calc(33.333% - 30px);
      }
    }
  }
}

@media all and (-ms-high-contrast: none) and (max-width: $breakpoint-sm-max), (-ms-high-contrast: active) and (max-width: $breakpoint-sm-max) {
  .event-cards{
    margin-left: 0;
    margin-right: 0;
    .eventAggregatorElement{
      display: flex;
      flex-flow: column;
      .vevent{
        margin: 15px 0;
        width: 100%;
      }
    }
  }

}
