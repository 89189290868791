/* Link Page Element */
// All Styles - Specific .goToLink icon referenced in _link_icons

// 1. Page Element Styles
.linkElement {
  h4 {
    font-family: $fontTwo;
    font-size: 12px;
    font-weight: 400;

    a {
      background-color: #00502f;
      display: inline-block;
      color: #fff;
      padding: 15px 20px;
      margin-bottom: 0px;
      text-decoration: none;
      text-transform: uppercase;
      transition: background-color .2s;

      &:hover {
        background-color: #003e25;
      }
    }
  }

  .goToLink,
  .emailLink {
    &:before {
      display: none;
    }

    &:after {
      // color: $accent-color;
      // content: "\f061"; // Right Arrow
      // font-family: FontAwesome;
      // display:inline-block;
      // line-height: .8em;
      // margin:0 0 0 6px;
      // padding:0;
      // position: relative;
      // transition: color .2s;
    }

    &:hover:after {
      // color: #fff;
    }
  }

  .emailLink {
    &:after {
      // content: "\f003"; // envelope-o
    }
  }

  .text {
    font-size: rem(13);
    line-height: 1.5em;
    margin: auto;
    padding: 10px;
    background-color: #eee;
  }
}

// 2. Admin Panel Styles
.admin .linkElement {
  h4 {
    font-family: $fontOne;
    font-size: rem(18);
    font-weight: 700;
    background: transparent;
    padding: 10px 0 5px 0;

    &:hover {
      background: transparent;
    }

    a {
      display: inline;
      color: $link-color;
      padding: 0;
      margin-bottom: 0px;
      background: transparent;
      text-decoration: none;
      opacity: 1;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .goToLink {
    &:before,
    &:after {
      display: none;
    }
  }

  .text {
    font-size: rem(13);
    padding: 0 0 10px 0;
    background-color: #fff;
  }
}


// Custom
.link-cta{
  .linkElement{
    background-color: $primary-color;
    padding: 45px 30px; // 25px 15px;
    border-top: 5px solid $neutral-color;
    h4{
      font-weight: 900;
      font-size: 24px;
      a, a:hover{
        padding: 0;
        text-transform: none;
        background-color: transparent;
      }
    }
    .text{
      font-size: 14px;
      font-style: normal;
      font-family: $fontTwo;
      color: #FFF;
      line-height: 1.2;
      padding: 0;
      background-color: transparent;
      margin-top: 10px;
    }
    &:after{
      font-family: 'fontawesome';
      font-size: 14px;
      line-height: 1;
      content: "\f054";
      display: inline-block;
      background-color: $neutral-color;
      color: $primary-color;
      padding: 5px 12px;
      margin-top: 15px;
      transition: padding $transition-linear;
    }
    &:hover:after{
      padding: 5px 28px;
    }
  }
  &.link-neutral{
    .linkElement{
      background-color: $neutral-color;
      border-top: 5px solid $primary-color;
      .text,
      h4 a,
      h4 a:hover{
        color: $gray-dk;
      }
      &:after{
        background-color: $primary-color;
        color: $neutral-color;
      }
    }
  }
}
