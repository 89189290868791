// 1. From Theme
// 2. From Breakpoints
// 3. Default Styles

$tabs-offset: 10px;
$tab-border-base-size: 1px;
$tab-border-size-hover: 5px;
$tab-text-color-hover: $primary-color;
$tab-border-color-hover: $primary-color;

@mixin tab-container-style{
  position: relative;
  z-index: 1;
  padding: 0 $tabs-offset;
  &:before{
    content: "";
    width: 100%;
    position: absolute;
    bottom: $tab-border-base-size; //2px;
    height: $tab-border-base-size; //2px;
    background-color: $gray-lt-x; // rgba(0,0,0,.2); //$gray-lt;
    left: 0;
    z-index: -1;
  }
}

// 1. From Theme
.pageEl {
  .tabbedElement {
    ul.contentTabs {
      border: none;

      li {
        span {
          a {
            display: block;
          }
        }
      }
    }

    .tabContainer {
      .current {
        // There is an inline width set here.
        // Need to uddate tabElement to remove.
        width: 100% !important;
      }
    }
  }
}

// Edit Mode Fixes
.edit_mode {
  .contentTabs.rapidTabsConstruction {
    li span a {
      color: #666;
      background: transparent;
      border-right: none;
    }

    .selected span a {
      background: #fccd22;
      color: #333;
    }
  }
}


// 2. From Breakpoints
// @media only screen and (max-width: 1024px) {
//   .tabbedElement {
//     ul.contentTabs {
//       li {
//         width: (100%/3);
//         padding: 2px;
//
//         span {
//           a {
//             width: 100%;
//             display: block;
//             text-align: center;
//           }
//         }
//       }
//     }
//   }
// }
//
// @media only screen and (max-width: 767px) {
//   .tabbedElement {
//     ul.contentTabs li {
//       width: 50%;
//       padding: 2px;
//
//       span a {
//         width: 100%;
//         display: block;
//         text-align: center;
//       }
//     }
//   }
// }
.pageEl,
#RosterPlayerStats,
.calendarContainer{
  .contentTabs{
    @include tab-container-style;
  }
}
#rapidTabs:not(.rapidTabsConstruction){
  @include tab-container-style;
}

// 3. Default Styles
.contentTabs {
  display: flex;
  flex-flow: row wrap;
  .tab-center & {
    justify-content: center;
    text-align: center;
  }
  list-style-type: none;
  text-align: left;
  width: 100%;
  // margin: 10px 0;
  + .tabContainer {
    margin-top: $page-element-margin;
  }

  &:after { clear: both; }

  &:before,
  &:after {
    content: " ";
    display: table;
  }

  li {
    @include content-tab-item;
    &:after{
      content: "";
      width: 100%;
      position: absolute;
      bottom: -($tab-border-base-size * 2);
      height: $tab-border-base-size;
      // background-color: $gray-lt;
      padding: 0 10px;
      left: -$tabs-offset;
      z-index: -1;
    }
    position: relative;
    span {
      background-repeat: no-repeat;
      background-position: bottom left;
      text-decoration: none;
      display: block;

      a {
        @include content-tab;
        transition: color $transition-linear;
        color: $gray-dk;

        span { display: inline; }
      }
    }

    &:hover,
    &.selected {
      border-bottom: $tab-border-size-hover solid $tab-border-color-hover;
      a:hover{
        color: $tab-text-color-hover;
      }
    }
  }

  &.rapidTabsConstruction a.tabLink {
    padding-top: 4px;
    padding-left: 8px;
    display: inline-block;
    background: none;
  }
}

.pageElementAdmin .rosterPlayerStatElement .contentTabs { margin: 0; }

.dataTable .disabled {
  color: #000;
  text-decoration: none;

  &:hover { text-decoration: underline; }
}


/* Loading Tab Animation
-------------------------------------------------------------------- */
#contentTabsLoader {
  text-align: center;
  clear: both;
  padding: 25px;
}


/* Rapid Tabs Construction
-------------------------------------------------------------------- */
.rapidTabsConstruction {
  background: #FDDD40;
  margin-bottom: 0;
  border-top: solid 1px #DDA301;
  padding: 0 0 1px;

  li {
    border-right: solid 1px #DDA301;
    padding-right: 0;

    a { font-size: 14px; }

    span {
      background: none;
      padding-left: 0;

      a {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }

  .selected { background: #FDE872; }
}

//
.tab-blue{
  .contentTabs li {
    &:hover,
    &.selected {
      border-bottom-color: $primary-color;
    }
  }
}
.tab-light{
  .contentTabs li {
    span a {
      color: #FFF;
    }
    &:hover,
    &.selected {
      a:hover{
        color: #FFF;
      }
    }
  }
}
