// Mobile Navigation

$mobile-nav-background-color: #FFF !default;
$mobile-nav-toggle-right: true !default;
$mobile-nav-toggle-width: 44px !default;
$mobile-nav-toggle-height: 44px !default;
$mobile-nav-toggle-position: center !default;
$mobile-nav-height: 60px !default;
$mobile-nav-align: center !default;
$mobile-nav-justification: flex-start !default;

// General Layout
.has-mobile-nav{
  .topNav{
    display: flex;
    flex-flow: row;
    align-items: $mobile-nav-align;
    justify-content: $mobile-nav-justification;
    height: $mobile-nav-height;
    position: relative;
    z-index: 200;
    background-color: $mobile-nav-background-color;
    @if $mobile-nav-toggle-right == true {
      padding-right: $mobile-nav-toggle-width;
    } @else {
      padding-left: $mobile-nav-toggle-width;
    }
  }
  body{
    padding-top: 0;
  }

  .theme-search-wrapper{
    display: none;
  }
  .theme-search-bar{
    .theme-search-box{
      display: none;
    }
  }
  .theme-mobile-nav{
    font-family: $fontTwo;
  }

  .theme-slide-nav-toggle{
    @if $mobile-nav-toggle-position == 'top' { top: 0; }
    @if $mobile-nav-toggle-position == 'center' { top: ($mobile-nav-height/2); transform: translateY(-50%); }
    @if $mobile-nav-toggle-position == 'bottom' { top: $mobile-nav-height - ($mobile-nav-toggle-height/2); transform: translateY(-50%); }
  }
  @if $mobile-nav-toggle-right {
    &.page-manager-visible:not(.slide-nav-open) .theme-slide-nav-toggle{
      right: calc(-100vw + 60px);
    }
    &:not(.slide-nav-open) .theme-slide-nav-toggle {
      right: -100%;
    }
  }

  body:before{
    display: none;
  }

}

$mobile-logo-width: 50px !default;
$mobile-logo-height: 50px !default;

.has-mobile-nav{
  .sn-site-logo-wrap{
    width: $mobile-logo-width;
    height: $mobile-logo-height;
    display: block;
  }
  .sn-site-logo-background{
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    display: block;
  }
}


.theme-mobile-logos{
  display: flex;
  justify-content: center;
  align-items: center;
  .site-nav-logo{
    line-height: 1;
    align-self: center;
    padding: 0 15px;
    + .site-nav-logo{
      border-left: 1px solid transparent;
      position: relative;
      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(0%, -50%);
        width: 1px;
        height: 25px;
        background-color: #DDD;
      }
    }
    [src*="tria-logo.svg"]{
      width: 148px;
    }
    [src*="wild-logo.svg"]{
      width: 49px;
    }
  }
  .site-nav-logo a,
  .site-nav-logo img{
    display: block;
  }
  .has-main-nav &{ display: none; }
}
