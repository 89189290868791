/* Feed Reader */

// Feed reader
.feeder{
  > h3{
    @include special-header;
    @include word-wrap();
  }
  h4{
    font-size: 18px;
    > a{
      color: $gray-dk;
      &:hover{
        color: $primary-color-dk;
      }
    }
  }
  > div:nth-of-type(1){
    > div{
      @include list-item();
      > ul li:last-child{
        > p:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  .even, .odd{
    > ul{
      list-style-type: none;
      ul{
        list-style-type: disc;
        padding-left: .5em;
        margin-bottom: 1em;
      }
      ol{
        margin-bottom: 1em;
      }
      ul li, ol li{
        margin-left: 1em;
      }
    }
  }
  blockquote{
    float: none;
    color: $bodyCopyColorTwo;
    font-size: initial;
    width: initial;
    padding: 0 1em;
    border-left: 2px solid #EEE;
    margin: 1em;
    &:before, &:after{display: none;}
  }
  img{
    max-width: 100%;
  }
  table{
    max-width: 100%;
    overflow-x: scroll;
    display: block;
  }
}

// Custom
.feed-hide-title{
  .feeder > h3{
    display: none;
  }
}
@mixin limit-list($limit){
  &:nth-child(n+#{$limit + 1}) {
    display: none;
  }
}
.feed-limit-2{
  .feeder > div:nth-of-type(1) > div{ @include limit-list(2); }
}
.feed-limit-3{
  .feeder > div:nth-of-type(1) > div{ @include limit-list(3); }
}
.feed-limit-4{
  .feeder > div:nth-of-type(1) > div{ @include limit-list(4); }
}
.feed-limit-5{
  .feeder > div:nth-of-type(1) > div{ @include limit-list(5); }
}
.feed-limit-6{
  .feeder > div:nth-of-type(1) > div{ @include limit-list(6); }
}
.feed-limit-7{
  .feeder > div:nth-of-type(1) > div{ @include limit-list(7); }
}
.feed-limit-8{
  .feeder > div:nth-of-type(1) > div{ @include limit-list(8); }
}
.feed-limit-9{
  .feeder > div:nth-of-type(1) > div{ @include limit-list(9); }
}
.feed-limit-10{
  .feeder > div:nth-of-type(1) > div{ @include limit-list(10); }
}
