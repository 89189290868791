// Page Layouts
// 1. Layout Containers, Columns, and Rows
// 2. Edit Mode
// 3. Page Title (display Body Header)
// 4. Page Height (Used to stick footer to bottom)
// The following can be removed if we had a standard layout.
// 5. News Article Page
// 6. Month List and Day View (Calendar)
// 7. Single Event Page
// 8. Sports Pages (Roster, Game Schedule, Player Stats, Team Stats, Photo, Video)
// 9. Error Notices

//Extras
@mixin padding-horizontal($amount){
  padding-left: $amount;
  padding-right: $amount;
}


// 1. Layout Containers, Columns, and Rows
.layoutContainer{
  background-color: $content-area-background;
  @include content-area-width;
  @include content-container-padding;
}
.full-width-container{
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
.full-width{
  max-width: 100%;
  > .column{
    max-width: 100%;
  }

  &.collapse-padding{
    @include padding-horizontal(0px);
    > .column{
      @include padding-horizontal(0px);
    }
  }
}
.column{
  position: relative;
  min-height: 1px;
  @include column-padding;
  @media screen and (max-width: $breakpoint-sm-max){
    width: 100%;
  }
  .edit_mode & {
    padding-left: 0;
    padding-right: 0;
  }

}
.row{
  @extend %clearfix;
  > .column{
    float: left;
  }
}
@media screen and (max-width: $breakpoint-sm-max) {
  .col{
    &-xs{
      &-12{ width: 100%; }
      &-11{ width: (11/12 * 100%); }
      &-10{ width: (10/12 * 100%); }
      &-9{ width: (9/12 * 100%); }
      &-8{ width: (8/12 * 100%); }
      &-7{ width: (7/12 * 100%); }
      &-6{ width: (6/12 * 100%); }
      &-5{ width: (5/12 * 100%); }
      &-4{ width: (4/12 * 100%); }
      &-3{ width: (3/12 * 100%); }
      &-2{ width: (2/12 * 100%); }
      &-1{ width: (1/12 * 100%); }
    }
  }
}
@media screen and (min-width: $breakpoint-md-min) {
  .col-md{
    &-12{ width: 100%; }
    &-11{ width: (11/12 * 100%); }
    &-10{ width: (10/12 * 100%); }
    &-9{ width: (9/12 * 100%); }
    &-8{ width: (8/12 * 100%); }
    &-7{ width: (7/12 * 100%); }
    &-6{ width: (6/12 * 100%); }
    &-5{ width: (5/12 * 100%); }
    &-4{ width: (4/12 * 100%); }
    &-3{ width: (3/12 * 100%); }
    &-2{ width: (2/12 * 100%); }
    &-1{ width: (1/12 * 100%); }
  }
  .full-width-container{
    .col-md{
      &-12{ max-width: ( ($content-area-width - $content-area-padding) * (12/12) ) }
      &-11{ max-width: ( ($content-area-width - $content-area-padding) * (11/12) ) }
      &-10{ max-width: ( ($content-area-width - $content-area-padding) * (10/12) ) }
      &-9{ max-width: ( ($content-area-width - $content-area-padding) * (9/12) ) }
      &-8{ max-width: ( ($content-area-width - $content-area-padding) * (8/12) ) }
      &-7{ max-width: ( ($content-area-width - $content-area-padding) * (7/12) ) }
      &-6{ max-width: ( ($content-area-width - $content-area-padding) * (6/12) ) }
      &-5{ max-width: ( ($content-area-width - $content-area-padding) * (5/12) ) }
      &-4{ max-width: ( ($content-area-width - $content-area-padding) * (4/12) ) }
      &-3{ max-width: ( ($content-area-width - $content-area-padding) * (3/12) ) }
      &-2{ max-width: ( ($content-area-width - $content-area-padding) * (2/12) ) }
      &-1{ max-width: ( ($content-area-width - $content-area-padding) * (1/12) ) }
    }
  }
}
@media screen and (min-width: $breakpoint-intermediate-min) and (max-width: $breakpoint-intermediate-max) {
  .layout-50-25-25,
  .layout-25-25-50 {
    .col-md-3 {
      width: (6/12 * 100%);
      max-width: ( ($content-area-width - $content-area-padding) * (6/12) )
    }
    .col-md-6 {
      width: 100%;
      max-width: ( ($content-area-width - $content-area-padding) * (12/12) )
    }
  }
  .layout-25-50-25 {
    .col-md-3,
    .col-md-6 {
      width: (4/12 * 100%);
      max-width: ( ($content-area-width - $content-area-padding) * (4/12) )
    }
  }
}

// 2. Edit Mode
.edit_mode .yieldPageContent{
  @include content-area-width;
}

// 3. Page Title (display Body Header)
#displayBodyHeader{
  @include page-element-padding;
  @include content-area-width;
  // @include content-container-padding(2);
  // @include header-accent($neutral-color);
}

// 4. Page Height (Used to stick footer to bottom)
.siteContainer {
  min-height: 100%;
  min-height: 100vh;
  min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
  @if $enable-full-width-layout != true {
    max-width: $content-area-width;
  } @else {
    position: relative;
    &:before{
      content: "";
      position: absolute;
      background-color: $content-area-background;
      box-shadow: 0px 2px 9px rgba(0, 0, 0, .05);
      top: $site-container-padding;
      bottom: $site-container-padding;
      left: 50%;
      z-index: -1;
      width: 100%;
      max-width: $content-area-width;
      transform: translateX(-50%);
    }
  }

}


// 5. News Article Page
.newsItemColumn{
  background-color: $content-area-background;
  @include content-area-width;
  @include content-container-padding;
}
.newsContentNode{
  + .column{
    @include content-area-width;
    @include content-container-padding(2);
  }
}

// 6. Month List and Day View (Calendar)
.calendarContainer{
  background-color: $content-area-background;
  @include content-area-width;
  @include content-container-padding(2);
}

// 7. Single Event Page
#event_show,
#event_show + .eventTags,
#event_show + .eventTags + .bottomOptions{
  background-color: $content-area-background;
  @include content-area-width;
  @include content-container-padding(2);
}

// 8. Sports Pages (Roster, Game Schedule, Player Stats, Team Stats, Photo, Video)
#sport-header{
  background-color: $content-area-background;
  @include content-area-width;
  @include content-container-padding(2);
}
.sportsPage{
  .yieldPageContent{
    > #galleryList,
    > #tooltips,
    > #sport-slider,
    > .contentTabs,
    > #contentTabsLoader,
    > .rapidContentGeneral,
    > .games-slider,
    > #TeamInstanceStatsDiv,
    > .bottomOptions{
      background-color: $content-area-background;
      @include content-area-width;
      @include content-container-padding(2);
    }
  }
}

// 9. Error Notices
#errorNotice{
  background-color: $content-area-background;
  @include content-area-width;
  @include content-container-padding(2);
}

.yieldPageContent > .noEntry.pageElement{
  @include content-area-width;
  @include content-container-padding(2);
}


//
.background-image{
  background-size: cover;
  background-position: center;
  .user_mode & {
    .background-image{
      display: none;
    }
  }
}
.background-cover-img{
  background-size: cover;
  background-position: center;
  > .layoutContainer{
    background-color: transparent;
    &.background-cover-img{
      background-image: none !important;
    }
  }
}
.padding{
  padding-top: 50px;
  padding-bottom: 50px;
  &-top{
    padding-top: 50px;
  }
  &-bottom{
    padding-bottom: 50px;
  }
}
.compact{
  &.pageEl .pageElement{margin-bottom: 0; margin-top: 0;}
  .pageEl .pageElement{margin-bottom: 0; margin-top: 0;}
  &-top{
    &.pageEl .pageElement{margin-top: 0;}
    .pageEl .pageElement{margin-top: 0;}
  }
  &-bottom{
    &.pageEl .pageElement{margin-bottom: 0;}
    .pageEl .pageElement{margin-bottom: 0;}
  }
}
