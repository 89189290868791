//

.header-special{
  .textBlockElement > h3{
    @include header-accent($neutral-color);
    @include header-accent-text;
  }
  &.header-sub-text{
    .text h4{
      font-size: 40px;
      @media screen and (max-width: $breakpoint-sm-max) { font-size: 24px; }
      text-transform: none;
      font-weight: 900;
    }
  }
}

.header-light .textBlockElement > h3 { color: #FFF; }
.header-primary .textBlockElement > h3 { color: $primary-color; }
.header-secondary .textBlockElement > h3 { color: $secondary-color; }
.header-neutral .textBlockElement > h3 { color: $neutral-color; }

.sub-text-light .text h4 { color: #FFF; }
.sub-text-primary .text h4 { color: $primary-color; }
.sub-text-secondary .text h4 { color: $secondary-color; }
.sub-text-neutral .text h4 { color: $neutral-color; }

.layoutContainer{
  &.background{
    &-gray{ background-color: $gray-lt; }
    &-gray-lt{ background-color: $gray-lt-x; }
    &-primary{ background-color: $primary-color-dk; }
    &-secondary{ background-color: $secondary-color; }
    &-neutral{ background-color: $neutral-color; }
    &-light{ background-color: #FFF; }
    &-diamond{
      .user_mode &{
        position: relative;
        &:before{
          content: "";
          position: absolute;
          top: -100px;
          right: 0;
          display: block;
          width: 33%;
          height: 100%;
          background-image: url('data:image/svg+xml;utf8,<svg id="Layer_2" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 165.4322205 110.0049362"><style>.st0{fill:#fff}</style><path class="st0" d="M110.428 0L86.5 23.926l31.074 31.076L86.5 86.077l23.928 23.928 55.004-55.003z"/><path transform="rotate(-44.995 82.715 55.002)" class="st0" d="M63.158 35.445h39.114v39.114H63.158z"/><path class="st0" d="M47.853 55.002L78.926 23.93 55 .002l-55 55L55 110l23.926-23.926z"/></svg>');
          background-size: cover;
          opacity: .5;
        }
      }
    }
  }
}

.element-card{
  .pageElement{
    padding: 15px;
    background-color: #FFF;
  }
}
