$custom-footer-max-width: $content-area-width !default;
$custom-footer-band-background-color: #DEE1E3 !default;


.snFooterContainer{
  background-color: $custom-footer-band-background-color;
}
.custom-footer{
  width: 100%;
  max-width: $custom-footer-max-width;
  padding: 75px $content-area-padding;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  @media screen and (max-width: $breakpoint-sm-max) {
    grid-template-columns: auto;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: -ms-grid;
    -ms-grid-columns: 1fr 30px 1fr 30px 1fr;
    // -ms-grid-rows: auto 30px 1fr 30px;
  }
  @media all and (-ms-high-contrast: none) and (max-width: $breakpoint-sm-max), (-ms-high-contrast: active) and (max-width: $breakpoint-sm-max) {
    display: flex;
    flex-flow: column;
  }

  .footer-contact{
    @media screen and (max-width: $breakpoint-sm-max) {
      h3{ text-align: center !important;}
      .text{
        text-align: center;
      }
    }
    .text p{
      color: $primary-color-dk;
    }
  }
  .footer-links{
    .text p{
      margin-bottom: .5em;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
}
.footer-column{
  display: flex;
  flex-flow: column;
  align-items: center;
  font-family: $fontTwo;
  &:nth-of-type(1){ @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { -ms-grid-column: 1; -ms-grid-row: 1; } }
  &:nth-of-type(2){ @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { -ms-grid-column: 3; -ms-grid-row: 1; } }
  &:nth-of-type(3){ @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) { -ms-grid-column: 5; -ms-grid-row: 1; } }
  &:nth-child(3){
    @media screen and (max-width: $breakpoint-sm-max) {
      display: none;
    }
  }
  h3{
    font-weight: 600;
    font-size: 22px;
  }
}
.site-footer-logo{
  text-align: center;
  a{
    display: inline-block;
  }
  [src*="tria-logo"]{
    display: block;
    // width: 100%;
    max-width: 302px;
    height: 38px;
    @media screen and (max-width: 430px) {
      max-width: 211px;
      height: 28px;
    }
    @media screen and (max-width: 260px) {
      width: 100%;
    }
  }
  [src*="wild-logo"]{
    display: block;
    // width: 100%;
    max-width: 101px;
    height: 62px;
    @media screen and (max-width: 430px) {
      max-width: 108px;
      height: 70px;
    }
    @media screen and (max-width: 260px) {
      width: 100%;
    }
  }
  + .site-footer-logo{
    margin-top: 10px;
  }
}
