$site-container-padding: 30px !default;
$display-body-header-font-family: $fontTwo;
$display-body-header-font-size: 32px;
$display-body-header-line-height: 1;
$display-body-header-font-weight: 900;
$display-body-header-text-case: none;
$display-body-header-text-align: left;
$display-body-header-text-color: $primary-color;
$display-body-header-content-padding: 30px;


.siteContainer {
  padding: $site-container-padding 0;
  .home:not(.newsPage) &{ padding: 0; }
  width: 100%;
  min-height: 100%;
  min-height: 100vh;
  min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
  margin: 0 auto;


  #displayBodyHeader:not(.displayBodyHeader-image) {
    border-bottom: $borderWidth solid $borderColor;
    font-family: $display-body-header-font-family;
    font-size: $display-body-header-font-size;
    line-height: $display-body-header-line-height;
    font-weight: $display-body-header-font-weight;
    text-transform: $display-body-header-text-case;
    text-align: $display-body-header-text-align;
    color: $display-body-header-text-color;
    @include content-container-padding(1);
    // padding-left: 0 !important;

  }

  #yieldContent.row { margin: auto; }

  #panelOne { padding: 0; }

  .yieldPageContent { padding:0; }
}
